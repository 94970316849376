/*
 * Application global variables.
 */

// Angular Material custom theme
// See https://material.angular.io/guide/theming for more details.
//
// You can also read https://medium.com/@tomastrajan/the-complete-guide-to-angular-material-themes-4d165a9d24d1
// for more insight about Angular Material theming.

@import "@angular/material/theming";

@import "palette";
@import "typography";

@include mat-core($template-typography);

//Custom palette

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$app-primary: mat-palette($template-primary-color, 900);
// stylelint-disable-next-line value-keyword-case
$app-accent:  mat-palette($template-accent-color, 600);

// The warn palette is optional (defaults to red).
$app-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

// Background colors
$app-background-dark: #282a36;
$app-background-light: #f5f5f5;
$app-background-light: #eceaea;
$app-background-text: #5f6368;
