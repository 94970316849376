@use '@angular/material' as mat;
@import "theme-layer.scss";

/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

// stylelint-disable-next-line selector-max-universal
* {
  box-sizing: border-box;
}

html,
body {
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100%;
}

// CUSTOM NATIVE STYLES ========================================================
// =============================================================================
a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
  color: mat.get-color-from-palette($app-primary);
}

// https://developer.mozilla.org/en-US/docs/Web/CSS/font-smooth
// The font-smooth CSS property controls the application of anti-aliasing
// when fonts are rendered.
strong {
  font-weight: 600!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Custom input time style
input[type="time"]::-webkit-calendar-picker-indicator{
  display: none;
}


// GOOGLE RECAPTCHA STYLES =====================================================
// =============================================================================

// Hidden tag
.grecaptcha-badge {
  visibility: hidden;
}

// MATERIAL OVERWRITE STYLES ===================================================
// =============================================================================

// Custom material container
.mat-container {
  padding: 1em;
}

// Ovewrite mat-menu styles
.mat-menu-panel .mat-menu-content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

// Ovewrite mat-card-tile size font title
.mat-card-title {
  font-size: 28px !important;
}

// Ovewrite mat-card-actions margin laterals
.mat-card-actions {
  margin: 0px !important;
}

// Ovewrite mat-form-field
.mat-form-field {
  line-height: 1 !important;
}

.mat-form-field-infix {
  width: auto !important;
}

// Custom option with suboption style
.mat-option-text span {
  line-height: 1rem;
}
.mat-option-text .mat-suboption-text {
  font-size: 10px;
  color: $app-background-text;
}

// Default transparent toolbar
.mat-toolbar {
  background: transparent;
}

// Custom line-height in mat-button-toggle
.mat-button-toggle-label-content {
  line-height: 36px !important;
}

// .mat-card-header-text {
//   margin: 0px !important;
// }
//
//
// .mat-dialog-container {
//   background-color: $app-background-light;
// }

.mat-dialog-container {
  background-color: $app-background-light;
}


// Custom form-field > input (disabled)
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  background-color: #eceaeacc;
}
// .mat-form-field-appearance-outline .mat-form-field-outline {
//   color: rgba(0, 0, 0, 0.20);
// }

// Custom truncate text
.mat-text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Custom condensed text
.mat-text-condensed {
  line-height: normal;
  text-align: justify;
}

// Custom stepper valid point
.mat-step-header .mat-step-icon-state-done {
  background-color: #4caf50 !important;
  color: white;
}

// Custom stepper invalid point
.mat-step-header .mat-step-icon-state-error {
  background-color: mat-color($app-warn) !important;
  color: white;
}

// mat-dialog full screen on mobile
@media only screen and (max-width: 620px){
  .mat-dialog-responsive {
    // width:100vw;
    // height:100vh;
    width:100%;
    height:100%;
    max-width: unset !important;
  }
}
// =============================================================================
