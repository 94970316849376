// =============================================================================
// ============================= DCX CUSTOM STYLES =============================
// =============================================================================
@import "theme-variables";
@import "palette-layer";

// COMPLEMENTARY PALETTE (Portfolio)
// =============================================================================

$dcx-pulses: mat-palette($dcx-pulses-color, 600);
$dcx-rice: mat-palette($dcx-rice-color, 500);
$dcx-sugar: mat-palette($dcx-sugar-color, 700);
$dcx-fertilizer: mat-palette($dcx-fertilizer-color, 500);
$dcx-finance: mat-palette($dcx-finance-color, 300);
$dcx-freight: mat-palette($dcx-freight-color, 900);
$dcx-grains: mat-palette($dcx-grains-color, 800);
$dcx-insurance: mat-palette($dcx-insurance-color, 500);

// =============================================================================

// (PROBABLY TEMPORARY) We reset default headings styles
// so it doesn't interfere with custom DCXs requirements (paddings, margins, etc)

h1,
h2,
h3,
h4,
h5,
h6 {
  all: unset;
}

$dcx-light-blue: #0065AB;
$dcx-lighter-blue: #418CC0;
$dcx-dark-blue: $app-primary;
$dcx-dark-grey: #444;
$dcx-light-grey: #7b7b7b;
$dcx-font-georama: Georama;
$dcx-font-opensans: OpenSans;

$dcx-trade-status-draft: #ffd102;
$dcx-trade-status-reviewed: #02a00a;
$dcx-trade-status-submitted: #4285fb;
$dcx-trade-status-canceled: #bc004b;


.dcx-text {
  font-family: $dcx-font-opensans !important;
  color: black !important;

  &.dcx-h1 {
    font-size: 20px !important;
  }

  &.dcx-h2 {
    font-size: 18px !important;
  }

  &.dcx-h3 {
    font-size: 16px !important;
  }

  &.dcx-h4 {
    font-size: 14px !important;
  }

  &.dcx-h5 {
    font-size: 13px !important;
  }

  &.dcx-h6 {
    font-size: 12px !important;
    text-decoration: underline !important;
  }

  &.dcx-h7 {
    font-size: 10px !important;
  }

  // Custom colors
  &.dcx-white {
    color: #fff !important;
  }

  &.dcx-blue {
    color: $dcx-light-blue !important;
  }

  &.dcx-lighter-blue {
    color: $dcx-lighter-blue !important;
  }

  &.dcx-darkgrey {
    color: $dcx-dark-grey !important;
  }

  &.lightgrey {
    color: $dcx-light-grey !important;
  }


  // Custom fonts
  &.georama {
    font-family: Georama !important;
  }
}

// https://stackoverflow.com/a/69226985/8108337
// Extend CSS and use additional attribute on the element to hide just specific use-case of it.
// This only applies when attribute hide-header is available. EG:
// <mat-stepper hide-header>
// ...
// <mat-stepper>
mat-stepper[hide-header] .mat-horizontal-stepper-header-container {
  display: none;
}

// cookie sheet styles (bottom-sheet api can use panelClass: Extra CSS classes to be added to the bottom sheet container)
// https://material.angular.io/components/bottom-sheet/api
.cookie-sheet-style {
  width: 300px!important;
  min-width: 300px!important;
  margin-bottom: 15px!important;

  @media (min-width: 600px) {
    width: 350px!important;
    min-width: 350px!important;

    margin-left: 15px!important;
    margin-right: 90vw!important;
  }
}

//Style for disable form fields
.mat-form-field-disabled .mat-form-field-infix .mat-input-element {
  color: black;
}

// Style for password validation
.password-hints {
  font-size: 12px;
}
.password-hints-title {
  font-size: 14px;
  color: black;
}
.password-hints .mat-icon {
  vertical-align: middle;
  transform: scale(.75);
}
.password-hints .pending-icon {
  color: #a0a0a0;
}
.password-hints .check-icon {
  color: #4ba056;
}

// Styles for company status
.mat-button-toggle.company-status-confirmed {
  color: mat-color($mat-green, 500);
}
.mat-button-toggle.company-status-rejected {
  color: mat-color($app-warn);
}
.mat-button-toggle.company-status-duplicated {
  color: mat-color($mat-orange, 500);
}
.mat-button-toggle-checked.company-status-confirmed {
  background-color: mat-color($mat-green, 500);
  color: white;
}
.mat-button-toggle-checked.company-status-rejected {
  background-color: mat-color($app-warn);
  color: mat-color($app-warn, default-contrast);
}
.mat-button-toggle-checked.company-status-duplicated {
  background-color: mat-color($mat-orange, 500);
  color: white;
}
.mat-button-toggle-disabled.company-status-confirmed {
  color: mat-color($mat-green, 500, 0.65);
}
.mat-button-toggle-disabled.company-status-rejected {
  color: mat-color($app-warn, 0.65);
}
.mat-button-toggle-disabled.company-status-duplicated {
  color: mat-color($mat-orange, 500, 0.65);
}


// Styles for company profile status
.mat-button-toggle.company-profile-status-submitted {
  color: mat-color($mat-orange, 500);
}
.mat-button-toggle.company-profile-status-accepted {
  color: mat-color($mat-blue, 500);
}
.mat-button-toggle.company-profile-status-approved {
  color: mat-color($mat-green, 500);
}
.mat-button-toggle.company-profile-status-rejected {
  color: mat-color($app-warn);
}
.mat-button-toggle-checked.company-profile-status-submitted {
  background-color: mat-color($mat-orange, 500);
  color: white;
}
.mat-button-toggle-checked.company-profile-status-accepted {
  background-color: mat-color($mat-blue, 500);
  color: white;
}
.mat-button-toggle-checked.company-profile-status-approved {
  background-color: mat-color($mat-green, 500);
  color: white;
}
.mat-button-toggle-checked.company-profile-status-rejected {
  background-color: mat-color($app-warn);
  color: mat-color($app-warn, default-contrast);
}
.mat-button-toggle-disabled.company-profile-status-submitted {
  color: mat-color($mat-orange, 500, 0.65);
}
.mat-button-toggle-disabled.company-profile-status-accepted {
  color: mat-color($mat-blue, 500, 0.65);
}
.mat-button-toggle-disabled.company-profile-status-approved {
  color: mat-color($mat-green, 500, 0.65);
}
.mat-button-toggle-disabled.company-profile-status-rejected {
  color: mat-color($app-warn, 0.65);
}

// Styles for trade operation
.mat-button-toggle.trade-operation-sell {
  color: mat-color($app-primary);
}
.mat-button-toggle.trade-operation-buy {
  color: mat-color($app-accent);
}
.mat-button-toggle-checked.trade-operation-sell {
  background-color: mat-color($app-primary);
  color: mat-color($app-primary, default-contrast);
}
.mat-button-toggle-checked.trade-operation-buy {
  background-color: mat-color($app-accent);
  color: mat-color($app-accent, default-contrast);
}
.mat-button-toggle-disabled.trade-operation-sell {
  color: #bdbbbb;
}
.mat-button-toggle-disabled.trade-operation-buy {
  color: #bdbbbb;
}

// Styles for trade status
.mat-button-toggle.trade-status-draft {
  color: $dcx-trade-status-draft;
}
.mat-button-toggle.trade-status-reviewed {
  color: $dcx-trade-status-reviewed;
}
.mat-button-toggle.trade-status-submitted {
  color: $dcx-trade-status-submitted;
}
.mat-button-toggle.trade-status-accepted {
  color: mat-color($mat-green, 500);
}
.mat-button-toggle.trade-status-canceled {
  color: $dcx-trade-status-canceled;
}
.mat-button-toggle.trade-status-expired {
  color: mat-color($mat-gray);
}
.mat-button-toggle-checked.trade-status-draft {
  background-color: $dcx-trade-status-draft !important;
  color: black !important;
}
.mat-button-toggle-checked.trade-status-reviewed {
  background-color: $dcx-trade-status-reviewed !important;
  color: white !important;
}
.mat-button-toggle-checked.trade-status-submitted {
  background-color: $dcx-trade-status-submitted !important;
  color: white !important;
}
.mat-button-toggle-checked.trade-status-accepted {
  background-color: mat-color($mat-green, 500) !important;
  color: white !important;
}
.mat-button-toggle-checked.trade-status-canceled {
  background-color: $dcx-trade-status-canceled !important;
  color: mat-color($app-warn, default-contrast) !important;
}
.mat-button-toggle-checked.trade-status-expired {
  background-color: mat-color($mat-gray) !important;
  color: mat-color($mat-gray, default-contrast) !important;
}
.mat-button-toggle-disabled.trade-status-draft {
  color: $dcx-trade-status-draft;
}
.mat-button-toggle-disabled.trade-status-reviewed {
  color: $dcx-trade-status-reviewed;
}
.mat-button-toggle-disabled.trade-status-submitted {
  color: $dcx-trade-status-submitted;
}
.mat-button-toggle-disabled.trade-status-accepted {
  color: mat-color($mat-green, 500, 0.65);
}
.mat-button-toggle-disabled.trade-status-canceled {
  color: $dcx-trade-status-canceled;
}
.mat-button-toggle-disabled.trade-status-expired {
  color: mat-color($mat-gray, 0.65);
}

// Style for trade products
.pulses-text {
  color: mat-color($dcx-pulses);
}

.pulses-background {
  background-color: mat-color($dcx-pulses, 50);
}

.grains-text {
  color: mat-color($dcx-grains);
}

.grains-background {
  background-color: mat-color($dcx-grains, 50);
}

.rice-text {
  color: mat-color($dcx-rice);
}

.rice-background {
  background-color: mat-color($dcx-rice, 50);
}

.fertilizer-text {
  color: mat-color($dcx-fertilizer);
}

.fertilizer-background {
  background-color: mat-color($dcx-fertilizer, 50);
}

.sugar-text {
  color: mat-color($dcx-sugar);
}

.sugar-background {
  background-color: mat-color($dcx-sugar, 50);
}
